import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login/Login'));


class App extends Component {


  render() {
    const token = localStorage.getItem('token');
    // const token = '';
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {!token &&  <Route  path="/" name="Login Page" render={props => <Login {...props}/>} />}
              {token &&  <Route path="/"  name="Home" render={props => <TheLayout {...props}/>} /> }
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
